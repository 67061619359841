import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

type NewPlanInfosCardProps = {
  title: string;
  street: string;
  federalState: string;
  country: string;
  account: string;
};

type InfoRowProps = {
  label: string;
  value: string;
  isMultiline?: boolean;
};

const NewPlanInfosCard: React.FC<NewPlanInfosCardProps> = ({
  title,
  street,
  federalState,
  country,
  account,
}) => {
  return (
    <Card className="card-block card-stretch card-height">
      <Card.Body>
        <h5 className='mb-3'>Newplan</h5>
        <DetailRow label="Titel" value={title} />
        <DetailRow label="Straße" value={street} />
        <DetailRow label="Bundesland" value={federalState} />
        <DetailRow label="Land" value={country} />
        <DetailRow label="Account" value={account} />
      </Card.Body>
    </Card>
  );
};

const DetailRow: React.FC<InfoRowProps> = ({ label, value, isMultiline = false }) => {
  return (
    <Row>
      <Col lg={4}>
        <p>{label}</p>
      </Col>
      <Col>
        {isMultiline ? (
          <p className='text-black text-black text-wrap text-break' dangerouslySetInnerHTML={{ __html: value }}></p>
        ) : (
          <p className='text-black text-black text-wrap text-break'>{value}</p>
        )}
      </Col>
    </Row>
  );
};

export default NewPlanInfosCard;
