import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { useSortableData } from '../../utils/hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApiKey, } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import { useSelection } from '../../utils/hooks/useSelection';
import SearchInput from '../../components/SearchInput';
import ApiKeyStatusDropdown from '../../components/ApiKeyStatusDropdown';
import AddEditApiKeyModal from '../../components/apiKeys/modal/AddEditApiKeyModal';
import { formatApiKey, formatDate } from '../../utils/utils';
import useResetUrlParams from '../../utils/hooks/useResetUrlParams';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

interface ApiKeysResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: ApiKey[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const ApiKeys = memo(() => {
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const { items: sortedApiKeys, requestSort } = useSortableData(apiKeys, false, { field: 'title', type: 'asc' });

  const getId = useCallback((apiKey: ApiKey) => apiKey.id, []);

  const {
    selectedItems: selectedApiKeys,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(apiKeys, getId);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchApiKeys = useCallback(async () => {
    setApiKeys([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      const response = await ApiClient.get(`/apikeys${queryParams}`);
      const apiKeysResponse = response.data as ApiKeysResponse
      setTotalPages(apiKeysResponse.amountPages);
      setApiKeys(apiKeysResponse.list);
      setCurrentPage(apiKeysResponse.page);
      setLimit(apiKeysResponse.itemsPerPage);
      setTotalEntries(apiKeysResponse.amountAllItems);
      setAvailableFilter(apiKeysResponse.searchFilters)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery, limit]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'read');

      if (hasPermission) {
        fetchApiKeys();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchApiKeys,
    permissionsLoaded,
    searchQuery,
    limit
  ]);

  const handleApiKeyUpdateSubmit = () => {
    fetchApiKeys();
  };

  const resetSearch = () => {
    resetUrlParams()
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  const handleApiKeyStatusUpdate = async (
    apikeyId: number,
    status: number
  ) => {
    try {
      await ApiClient.put(`/apikeys/${apikeyId}`, {
        status,
      });

      await fetchApiKeys();

      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>API-Schlüssel</h3>
        <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
          API-Schlüssel hinzufügen
        </Button>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel/API-Schlüssel
              </th>

              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('expiration')}
              >
                Ablaufdatum
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('description')}
              >
                Beschreibung
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedApiKeys}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleApiKeyUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='apikeys'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedApiKeys.map((apiKey) => (
                <tr key={apiKey.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedApiKeys[apiKey.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(apiKey.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/apikeys/${apiKey.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {formatApiKey(apiKey.title)}
                    </Link>
                  </td>
                  <td>
                    {formatDate(apiKey?.expiration, 'd.m.Y H:i:s')}
                  </td>
                  <td>
                    {apiKey.description}
                  </td>
                  <td>
                    <ApiKeyStatusDropdown isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewApiKeys, 'write')} statusApiKey={apiKey.status} handleApiKeyStatusUpdate={(status) => handleApiKeyStatusUpdate(apiKey.id, status)}></ApiKeyStatusDropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedApiKeys.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine API-Schlüssel gefunden</p>
          </div>
        )}
      </div>

      {totalEntries > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      {showAddEditModal && <AddEditApiKeyModal
        modalTitle={`API-Schlüssel hinzufügen`}
        onSubmitSuccess={handleApiKeyUpdateSubmit}
        onModalClose={() => setShowAddEditModal(false)}
      >
      </AddEditApiKeyModal>
      }

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default ApiKeys;
