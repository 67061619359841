import React from 'react';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

type MapProps = {
  latitude: string;
  longitude: string;
};

const MapContainer: React.FC<MapProps> = ({ latitude, longitude }) => {
  const containerStyle = {
    width: '100%',
    height: '200px',
  };

  const center = {
    lat: Number(latitude),
    lng: Number(longitude),
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      options={{
        disableDefaultUI: true
      }}
    >
      <Marker position={center}></Marker>
      <></>
    </GoogleMap>
  ) : (
    <></>
  );

};

export default React.memo(MapContainer)
