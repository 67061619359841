import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { User, UserRole } from '../../../interfaces';
import SkeletonCard from '../../locations/skeleton/SkeletonCard';
import { ActiveInactiveMapping, CompanyEnum, PermissionsEnum, getEnumValue } from '../../../utils/enum';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import AddEditUsersModal from '../modal/AddEditUsersModal';

interface UserDetailsProps {
    isLoading?: boolean;
    user?: User | null;
    userRoles?: UserRole[]
    onSubmitSuccess: (certificateId: number) => void;
}

const UserDetails: React.FC<UserDetailsProps> = ({
    isLoading,
    user,
    onSubmitSuccess,
    userRoles
}) => {
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const { userHasPermissionByRight } = usePermissions();

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel',
                value: user?.title || '-',
            },
            {
                label: 'Benutzergruppe',
                value: user?.roles?.title || '-',
            },
            {
                label: 'Status',
                value: getEnumValue(ActiveInactiveMapping, user?.status.toString()),
            },
            {
                label: 'Unternehmen',
                value: user?.company ? ((user.company) as (keyof typeof CompanyEnum)[]).map(companyKey => CompanyEnum[companyKey]).join(', ') : '',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'E-Mail-Adresse',
                value: user?.email || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{user?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewUsers, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Anmeldedaten"
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {user && userRoles && showAddEditModal && <AddEditUsersModal
                modalTitle={`${user.title} bearbeiten`}
                user={user}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
                userRoles={userRoles}
            >
            </AddEditUsersModal>
            }
        </>
    );
};

export default UserDetails;
