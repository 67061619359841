import React from 'react';
import GenericInfosCard from '../products/GenericInfosCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { MediaItem } from '../../interfaces';
import { formatSizeUnits, getImageUrl, getMediaIcon, getMediaStatusIcons, getMediaType } from '../../utils/media/utils';

interface SelectionActionsProps {
    media: MediaItem;
    onClose: () => void;
}

const MediaDetails: React.FC<SelectionActionsProps> = ({
    media,
    onClose
}) => {
    function getMediaInfo() {
        const generalInfo = [
            { label: 'Typ:', value: getMediaType(media) },
            { label: 'Mime-Typ:', value: media?.mimeType || '-' },
            { label: 'Größe:', value: media.size ? formatSizeUnits(media.size) : '-' },
            {
                label: 'Abmessungen:',
                value: media.width && media.height ? `${media.width} x ${media.height}` : '-'
            },
            {
                label: 'Beschreibung:',
                value: media?.description || '-'
            }
        ];
        return generalInfo;
    }

    return (
        <>
            <GenericInfosCard
                imageSrc={getImageUrl(media)}
                title={'Eigenschaften'}
                infos={getMediaInfo()}
                showHorizontalLine
                cardHeight={false}
                firstColumnSize={5}
            >

                <div className='d-flex align-items-center justify-content-between p-3'>
                    <div className='d-flex align-items-center'>
                        {getMediaIcon(media)} {getMediaStatusIcons(media)} <h5 className='mb-0'>{media.title}</h5>
                    </div>

                    <div>
                        <FontAwesomeIcon
                            icon={faClose}
                            onClick={onClose}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>

            </GenericInfosCard>
        </>
    );
};

export default MediaDetails;
