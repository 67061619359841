import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PortalWrapper from '../PortalWrapper';

// Helper function to get the icon by the filter
const getIconByFilter = (key: string | number, iconMap?: { [key: string]: any }, colorMap?: { [key: string]: string }) => {
    if (!iconMap || !iconMap[key]) return null;
    const iconClass = colorMap && colorMap[key] ? `me-2 ${colorMap[key]}` : 'me-2';
    return <FontAwesomeIcon width={15} icon={iconMap[key]} className={iconClass} />;
};

/**
 * GenericDropdownFilter is a flexible and reusable dropdown component for filtering a list of options.
 * It supports dynamic filter options, optional icons, and customizable colors for each filter item.
 * 
 */
interface GenericDropdownFilterProps<T extends string | number> {
    selectedFilter: T | null;
    handleFilterChange: (filter: T | null) => void;
    filterEnum: { [key in T]?: string }; // Options enum
    iconMap?: { [key: string]: any }; // Optional mapping of filter keys to icons
    colorMap?: { [key: string]: string }; // Optional mapping of filter keys to color classes
    isDisabled?: boolean;
    titlePlaceholder?: string;
}

const GenericDropdownFilter = <T extends string | number>({
    selectedFilter,
    handleFilterChange,
    filterEnum,
    iconMap,
    colorMap,
    isDisabled,
    titlePlaceholder = 'Filter',
}: GenericDropdownFilterProps<T>) => {
    const handleReset = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleFilterChange(null);
    };

    const title = selectedFilter !== null
        ? filterEnum[selectedFilter]
        : titlePlaceholder;

    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="outline-primary"
                id="dropdown-filter"
                className="custom-dropdown-focus me-2"
            >
                {selectedFilter !== null && (
                    <FontAwesomeIcon icon={faTimes} className="me-2 cursor-pointer" onClick={handleReset} />
                )}
                {title}
            </Dropdown.Toggle>
            <PortalWrapper>
                <Dropdown.Menu>
                    {Object.entries(filterEnum).map(([key, value]) => (
                        <Dropdown.Item
                            key={key}
                            eventKey={key}
                            onClick={() => handleFilterChange(key as T)}
                        >
                            {getIconByFilter(key, iconMap, colorMap)}
                            <span>{value as string}</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </PortalWrapper>
        </Dropdown>
    );
};

export default GenericDropdownFilter;
