import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import MapContainer from './MapsContainer';

type GoogleInfosCardProps = {
  googleBusinessEntry: string;
  latitude: string;
  longitude: string;
};

type InfoRowProps = {
  label: string;
  value: string | number
  isMultiline?: boolean;
  isLink?: boolean
};

const GoogleInfosCard: React.FC<GoogleInfosCardProps> = ({
  googleBusinessEntry,
  latitude,
  longitude
}) => {
  return (
    <Card className="card-block card-stretch card-height">
      <Card.Body>
        <h5 className='mb-3'>Google</h5>
        <DetailRow label="Google Business Eintrag (URL)" value={googleBusinessEntry} isLink={true} />
        <DetailRow label="Latitude" value={latitude} />
        <DetailRow label="Longitude" value={longitude} />
        <MapContainer latitude={latitude} longitude={longitude} />
      </Card.Body>
    </Card>
  );
};

const DetailRow: React.FC<InfoRowProps> = ({ label, value, isMultiline = false, isLink = false }) => {
  return (
    <Row>
      <Col lg={4}>
        <p>{label}</p>
      </Col>
      <Col>
        {isMultiline ? (
          <p className='text-black text-wrap text-break' dangerouslySetInnerHTML={{ __html: value.toString() }}></p>
        ) : isLink ? (
          <a href={value.toString()} target="_blank" rel="noreferrer" className='text-black text-decoration-underline' style={{ color: 'inherit' }}>
            {value}
          </a>
        ) : (
          <p className='text-black text-wrap text-break'>{value}</p>
        )}
      </Col>
    </Row>
  );
};

export default GoogleInfosCard;
