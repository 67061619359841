import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

type ImportantInfosCardProps = {
  imageSrc: string;
  partnerName: string;
  lidIdent: string;
  address: string;
  phone: string;
  fax: string;
  mobile: string;
  email: string;
  title: string
  version: string
};

type InfoRowProps = {
  label: string;
  value: string;
  isMultiline?: boolean;
};

const ImportantInfosCard: React.FC<ImportantInfosCardProps> = ({
  imageSrc,
  partnerName,
  lidIdent,
  address,
  phone,
  fax,
  mobile,
  email,
  title,
  version
}) => {
  return (
    <Card className="card-block card-stretch">
      <Card.Body>
        <div className='d-flex justify-content-items'>
          <img
            className="img-fluid w-100 mb-3 rounded generic-info-card-image"
            src={imageSrc}
            alt="Location"
          />
        </div>
        <h5>{title}</h5>
        <div className='horizontal-line my-3'></div>
        <DetailRow label="Partner" value={partnerName} />
        <DetailRow label="Lid-Ident" value={lidIdent} />
        <DetailRow label="Version" value={version} />
        <DetailRow label="Adresse" value={address} isMultiline={true} />
        <DetailRow label="Festnetz" value={phone} />
        <DetailRow label="Fax" value={fax} />
        <DetailRow label="Mobil" value={mobile} />
        <DetailRow label="E-Mail-Adresse" value={email} />
      </Card.Body>
    </Card>
  );
};

const DetailRow: React.FC<InfoRowProps> = ({ label, value, isMultiline = false }) => {
  return (
    <Row>
      <Col lg={4}>
        <p>{label}</p>
      </Col>
      <Col>
        {isMultiline ? (
          <p className='text-black text-black text-wrap text-break' dangerouslySetInnerHTML={{ __html: value }}></p>
        ) : (
          <p className='text-black text-black text-wrap text-break'>{value}</p>
        )}
      </Col>
    </Row>
  );
};

export default ImportantInfosCard;
