import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { Focus } from '../../interfaces';
import { mockFocuses } from './focuses';
import FocusDetails from '../../components/focuses/detail/FocusDetails';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

function FocusDetail() {
  const { focusId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/focuses')
  const [focus, setFocus] = useState<Focus>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, userHasPermission, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewFocuses, 'read');

      if (hasPermission) {
        focusId && fetchFocus(focusId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [focusId, permissionsLoaded]);

  const fetchFocus = async (focusId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/keywords/${keywordId}`);
      // const keywordResponse = response.data;
      setFocus(mockFocuses[0]);
      setIsLoading(false);
    } catch (error) { 
      setIsLoading(false)
      setHttpError(error)
    }
  };

  const handleModalSubmit = () => {
    setFocus(undefined)
    focusId && fetchFocus(focusId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <FocusDetails focus={focus} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></FocusDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default FocusDetail;
