import React, { FormEvent, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import ComboButtonGroup, { ComboButtonId } from '../../ComboButtonGroup';
import { ApiClient } from '../../../services/ApiClient';
import {
  ActiveInactiveMapping,
  Countries,
  GermanStates,
  getEnumValue,
  PermissionsEnum,
  YesNoMapping,
} from '../../../utils/enum';
import { AccountId, Location, MediaItem, MeinNowAccounts, Partner, PartnerNames, PaymentMethods, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import MediaPickerModal from '../../media/modal/MediaPickerModal';
import MediaPickerMultiModal from '../../media/modal/MediaPickerMultiModal';
import SelectWithSearch from '../../form/SelectWithSearch';
import DeleteModal from '../../DeleteModal';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import ArchiveModal from '../../ArchiveModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import DropdownWithAddOption from '../../DropdownWithAddOption';
import { sanitizeInput } from '../../../utils/utils';
import AddressAutocomplete from '../../AddressAutocomplete';
import Card from '../../bootstrap/card';
import SelectedMediaPreview from '../../media/SelectMediaPreview';
import { handleInputChange } from '../../../utils/form/utils';

type AddEditLocationModalProps = {
  modalTitle: string;
  location?: Location;
  onSubmitSuccess: (locationsId: number) => void;
  onAccountsUpdate: () => void;
  onPartnerUpdate: () => void;
  onPaymentMethodsUpdate: () => void;
  onModalClose: () => void;
  accounts?: AccountId
  partner?: Partner
  paymentMethods?: PaymentMethods
};

interface SelectOption {
  value: string;
  label: string;
}

interface FormValues {
  title: string;
  name: string;
  accountId: string;
  city: string;
  country: string;
  email: string;
  fax: string;
  googleBusiness: string;
  zipcode: string;
  mobile: string;
  partner: string;
  state: string;
  status: number;
  street: string;
  streetNr: string;
  telephone: string;
  contractDateOfTermination: string;
  contractEnd: string;
  contractMinRentalPeriod: number;
  contractNoticePeriod: number;
  contractRentalStatus: number
  contractStart: string;
  costsDeposit: string
  costsFurtherRents: number;
  costsMonthlyGross: number;
  costsMonthlyNet: string
  costsOfTermination: string
  costsOneTime: string
  costsPaymentFirstRent: string;
  costsPaymentMethod: string;
  costsRentIncrease: string;
  costsTax: number;
  costsTaxDuty: boolean
  landlordContactPerson: string;
  landlordEmail: string;
  landlordIban: string;
  landlordIntendedUse: string;
  landlordName: string;
  utilization: number;
}

const comboButtonsContract = [
  { id: 'location_contract', label: 'Vertrag' },
  { id: 'location_document', label: 'Dokumente' },
  { id: 'location_images', label: 'Bilder' },
  { id: 'location_landlord', label: 'Vermieter' },
  { id: 'location_costs', label: 'Kosten' },
];

const AddEditLocationModal: React.FC<AddEditLocationModalProps> = ({
  modalTitle,
  location,
  onSubmitSuccess,
  onAccountsUpdate,
  onPartnerUpdate,
  onPaymentMethodsUpdate,
  onModalClose,
  accounts,
  partner,
  paymentMethods,
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const { locationId } = useParams<{ locationId?: string }>();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('location_contract');
  const companyNavigate = useCompanyNavigate()
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const { userHasPermissionByRight } = usePermissions();
  const [showStreetNr, setShowStreetNr] = useState(false);
  const [isMissingAddress, setIsMissingAddress] = useState(false);
  const [showContractMediaUploadModal, setShowContractMediaUploadModal] = useState(false);
  const [showImageMediaUploadModal, setShowImageMediaUploadModal] = useState(false);
  const [showMainImageSelectionModal, setShowMainImageSelectionModal] = useState(false);
  const [formValues, setFormValues] = useState({
    title: location?.title ?? '',
    name: location?.name ?? '',
    accountId: location?.accountId ?? '',
    city: location?.city ?? '',
    country: location?.country ?? '',
    email: location?.email ?? '',
    fax: location?.fax ?? '',
    googleBusiness: location?.googleBusiness ?? '',
    googleLat: location?.googleLat ?? '',
    googleLong: location?.googleLong ?? '',
    hubspotId: location?.hubspotId ?? '',
    lidIdent: location?.lidIdent ?? '',
    mobile: location?.mobile ?? '',
    partner: location?.partner ?? '',
    state: location?.state ?? '',
    status: location?.status ?? 2,
    statusKursnet: location?.statusKursnet ?? '',
    street: location?.street ?? '',
    streetNewplan: location?.streetNewplan ?? '',
    streetNr: location?.streetNr ?? '',
    telephone: location?.telephone ?? '',
    titleNewplan: location?.titleNewplan ?? '',
    zipcode: location?.zipcode ?? '',
    contractMedia: location?.contractMedia || [],
    imageMedia: location?.imageMedia || [],
    mainImage_id: location?.mainImage_id ?? null,
    mainImage: location?.mainImage,
    contractDateOfTermination: location?.contractDateOfTermination ?? '0000-00-00 00:00:00',
    contractEnd: location?.contractEnd ?? '0000-00-00 00:00:00',
    contractMinRentalPeriod: location?.contractMinRentalPeriod ?? 0,
    contractNoticePeriod: location?.contractNoticePeriod ?? 0,
    contractRentalStatus: location?.contractRentalStatus ?? 0,
    contractStart: location?.contractStart ?? '0000-00-00 00:00:00',
    costsDeposit: location?.costsDeposit ? location?.costsDeposit?.toString().replace(/\./g, ',') : "",
    costsFurtherRents: location?.costsFurtherRents ?? 0,
    costsMonthlyGross: location?.costsMonthlyGross ?? 0,
    costsMonthlyNet: location?.costsMonthlyNet ? location?.costsMonthlyNet?.toString().replace(/\./g, ',') : "",
    costsOfTermination: location?.costsOfTermination ? location?.costsOfTermination?.toString().replace(/\./g, ',') : "",
    costsOneTime: location?.costsOneTime ? location?.costsOneTime?.toString().replace(/\./g, ',') : "",
    costsPaymentFirstRent: location?.costsPaymentFirstRent ?? '0000-00-00 00:00:00',
    costsPaymentMethod: location?.costsPaymentMethod ?? '',
    costsRentIncrease: location?.costsRentIncrease ?? '',
    costsTax: location?.costsTax ?? 0,
    costsTaxDuty: Boolean(location?.costsTaxDuty) ?? false,
    landlordContactPerson: location?.landlordContactPerson ?? '',
    landlordEmail: location?.landlordEmail ?? '',
    landlordIban: location?.landlordIban ?? '',
    landlordIntendedUse: location?.landlordIntendedUse ?? '',
    landlordName: location?.landlordName ?? '',
    utilization: location?.utilization ?? 0,
    certificates_id: location?.certificates_id ?? null,
    certificate: location?.certificate
  });
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  const { street, streetNr, zipcode, city, state, country } = formValues;
  const isAddressComplete = street && zipcode && streetNr && city && state && country;

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!isAddressComplete) {
      setIsMissingAddress(true)
      return
    } else {
      setIsMissingAddress(false)
    }

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        if (locationId) {
          response = await ApiClient.put(
            `/locations/${locationId}`,
            transformFormValues(formValues)
          );
        } else {
          response = await ApiClient.post('/locations', transformFormValues(formValues));
        }
        const locationsId = response.data.id;
        onSubmitSuccess(locationsId);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose()
        setValidated(false);
      }
    }
  };


  function transformFormValues(values: FormValues): FormValues {
    const transformedValues = { ...values };

    if (transformedValues.costsDeposit) {
      transformedValues.costsDeposit = transformedValues.costsDeposit.toString().replace(/,/g, '.')
    }

    if (transformedValues.costsMonthlyNet) {
      transformedValues.costsMonthlyNet = transformedValues.costsMonthlyNet.toString().replace(/,/g, '.')
    }

    if (transformedValues.costsOfTermination) {
      transformedValues.costsOfTermination = transformedValues.costsOfTermination.toString().replace(/,/g, '.')
    }

    if (transformedValues.costsOneTime) {
      transformedValues.costsOneTime = transformedValues.costsOneTime.toString().replace(/,/g, '.')
    }

    return transformedValues;
  }

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    infoLabel: string = '',
    disabled = false
  ) => {

    const formatDate = (dateStr: string = "") => {
      if (type === 'date') {
        return dateStr.split(' ')[0];
      }
      return dateStr;
    };

    return (
      <Form.Group className="mb-3 w-100" controlId={id.toString()}>
        <Form.Label style={{ color: 'black' }}>{label} {infoLabel && <small>{infoLabel}</small>}</Form.Label>
        <Form.Control
          className={`text-black ${disabled ? 'bg-dark-grey' : 'bg-grey'}`}
          type={type}
          value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]?.toString()) : ''}
          onChange={(e) => handleInputChange(e, setFormValues)}
          required={required}
          isInvalid={validated && !formValues[id]}
          disabled={disabled}
        />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false,
    disabled = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id]?.toString()}
        onChange={(e) => handleInputChange(e, setFormValues)}
        required={required}
        className={`text-black ${disabled ? 'bg-dark-grey' : 'bg-grey'}`}
        disabled={disabled}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const handleMediaSelect = (media: MediaItem | MediaItem[], useCase: string) => {
    const mediaArray = Array.isArray(media) ? media : [media];

    const updateMediaMap = (existingMedia: MediaItem[], newMedia: MediaItem[]) => {
      const mediaMap = new Map(existingMedia.map(item => [item.id, item]));

      newMedia.forEach(m => {
        mediaMap.set(m.id, { ...mediaMap.get(m.id), ...m });
      });

      return Array.from(mediaMap.values());
    };

    switch (useCase) {
      case 'main-image':
        const mainImage = mediaArray[0];
        setFormValues({
          ...formValues,
          mainImage,
          mainImage_id: mainImage.id,
        });
        break;

      case 'contract-media':
        setFormValues({
          ...formValues,
          contractMedia: updateMediaMap(formValues.contractMedia, mediaArray),
        });
        break;

      case 'image-media':
        setFormValues({
          ...formValues,
          imageMedia: updateMediaMap(formValues.imageMedia, mediaArray),
        });
        break;

      default:
        break;
    }
  };

  const handleMediaDelete = (media: MediaItem, useCase: string) => {
    const updateMediaArray = (key: 'contractMedia' | 'imageMedia') => {
      setFormValues({
        ...formValues,
        [key]: formValues[key].filter(item => item.id !== media.id),
      });
    };

    switch (useCase) {
      case 'contract-media':
        updateMediaArray('contractMedia');
        break;

      case 'image-media':
        updateMediaArray('imageMedia');
        break;

      case 'main-image':
        setFormValues({
          ...formValues,
          mainImage_id: null,
          mainImage: null,
        });
        break;

      default:
        break;
    }
  };

  const handleSelectChange = (
    id: string,
    selectedOption: { value: string; label: string } | null
  ) => {
    console.log(selectedOption)
    setFormValues((prev: any) => ({
      ...prev,
      [id]: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    onModalClose()
    companyNavigate('/locations')
  }

  const handleAddressSelect = (address: {
    street: string;
    streetNumber: string;
    zipcode: string;
    city: string;
    state: string;
    country: string
  }) => {
    setFormValues((prev: any) => ({
      ...prev,
      street: address.street ?? '',
      streetNr: address.streetNumber ?? '',
      zipcode: address.zipcode ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      country: address.country ?? '',
    }));
  };

  const handleAddNewAccount = async (newOption: string) => {
    try {
      const res = await ApiClient.get('/settings?ident=meinNowAccounts')

      const setting = res.data.list[0] as Setting
      const details = (res.data.list[0].details || {}) as MeinNowAccounts

      const currentAccountNumbers = Object.values(details).map(detail => {
        const match = detail.match(/Account (\d+) - \[\d+\]/);
        return match ? parseInt(match[1], 10) : 0;
      });
      const nextAccountNumber = Math.max(...currentAccountNumbers) + 1;

      const newDetails = {
        ...details,
        [newOption]: `Account ${nextAccountNumber} - [${newOption}]`
      }

      await ApiClient.put(
        `/settings/${setting?.id}`,
        { details: newDetails }
      );
      setFormValues((prev) => ({
        ...prev,
        accountId: newOption || '',
      }));
      onAccountsUpdate()
      showToast('Erfolgreich gespeichert', false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
    }
  };

  const handleAddPartner = async (newOption: string) => {
    try {
      const res = await ApiClient.get('/settings?ident=locationsPartners')

      const setting = res.data.list[0] as Setting
      const details = (res.data.list[0].details || {}) as PartnerNames

      const newDetails = {
        ...details,
        [sanitizeInput(newOption)]: newOption
      }

      await ApiClient.put(
        `/settings/${setting?.id}`,
        { details: newDetails }
      );
      setFormValues((prev) => ({
        ...prev,
        partner: sanitizeInput(newOption) || '',
      }));
      onPartnerUpdate()
      showToast('Erfolgreich gespeichert', false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
    }
  };

  const handleAddPaymentMethod = async (newOption: string) => {
    try {
      const res = await ApiClient.get('/settings?ident=paymentMethods')

      const setting = res.data.list[0] as Setting
      const details = (res.data.list[0].details || {}) as PaymentMethods

      const newDetails = {
        ...details,
        [sanitizeInput(newOption)]: newOption
      }

      await ApiClient.put(
        `/settings/${setting?.id}`,
        { details: newDetails }
      );

      setFormValues((prev) => ({
        ...prev,
        costsPaymentMethod: sanitizeInput(newOption) || '',
      }));

      onPaymentMethodsUpdate()
      showToast('Erfolgreich gespeichert', false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
    }
  };
  const renderAddress = (formValues: FormValues): JSX.Element => {
    return (
      <div className="text-black my-5">
        <h6 className="text-decoration-underline">Ausgewählte Adresse:</h6>
        {isAddressComplete ? (
          <>
            <div>{`${street} ${streetNr}`}</div>
            <div>{`${zipcode} ${city}`}</div>
            <div>{`${getEnumValue(GermanStates, state)}, ${getEnumValue(Countries, country)}`}</div>
          </>
        ) : (
          isMissingAddress ? (
            <p className='text-danger'>Bitte Adresse eingeben!</p>
          ) : (
            <p>Keine Adresse ausgewählt</p>
          )
        )}
        <div className="mt-3">
          {showStreetNr ? (
            createFormGroup('streetNr', 'Hausnummer')
          ) : (
            <Form.Check
              className="text-black"
              type="switch"
              id="toggle-streetNr"
              label="Hausnummer manuell anpassen"
              checked={showStreetNr}
              onChange={() => setShowStreetNr(prev => !prev)}
              isInvalid={false}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button p-0"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>

                {location && location?.status === 3 && <DeleteModal
                  modalTitle="Auswahl löschen?"
                  buttonName="Löschen"
                  entityIds={[location?.id!]}
                  entityType='locations'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewLocations, 'delete')}>
                </DeleteModal>}

                {location && location?.status !== 3 && <ArchiveModal
                  modalTitle="Auswahl archivieren?"
                  buttonName="Archivieren"
                  entityIds={[location?.id!]}
                  entityType='locations'
                  onSubmit={() => onSubmitSuccess(location?.id!)}
                  onArchiveModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewLocations, 'delete')}>
                </ArchiveModal>}

              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <>
              <Row>
                <Col lg={4}>
                  <Card className='card-height'>
                    <Card.Body>
                      <h5>Generelle Informationen</h5>
                      <Form.Group className="mb-3 w-100 d-flex flex-column">
                        <Form.Label className='text-black'>Haupt Bild</Form.Label>

                        {formValues.mainImage && (
                          <SelectedMediaPreview
                            selectedMediaForMultiSelect={[formValues.mainImage]}
                            handleDeleteMedia={(media) => handleMediaDelete(media, 'main-image')}
                            colProps={12}
                          />
                        )}
                        <div className='d-flex flex-1 w-100 mt-3'>
                          <Button
                            className="btn-soft-primary w-100"
                            onClick={() => {
                              setShowMainImageSelectionModal(true);
                            }}
                          >
                            {formValues.mainImage ? 'Medium ersetzen' : 'Medium auswählen'}
                          </Button>
                        </div>
                      </Form.Group>
                      {location && createFormGroup('title', 'Titel')}
                      {partner && <DropdownWithAddOption
                        id="partner"
                        label="Partner"
                        options={Object.entries(partner).map(([value, label]) => ({
                          value,
                          label,
                        }))}
                        initialValue={formValues.partner.toString()}
                        onChange={(selectedOption) => {
                          setFormValues((prev) => ({
                            ...prev,
                            partner: selectedOption || '',
                          }));
                        }}
                        onAddOption={handleAddPartner}
                        placeholder="Auswählen..."
                      />}
                      <AddressAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
                        onAddressSelect={handleAddressSelect}
                      />

                      {renderAddress(formValues)}

                      {createFormGroup(
                        'telephone',
                        'Festnetz (Vorwahl + Rufnummer)'
                      )}
                      {createFormGroup('fax', 'Fax (Vorwahl + Rufnummer)')}
                      {createFormGroup('mobile', 'Mobil / Handy')}
                      {createFormGroup('email', 'E-Mail Adresse')}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <Card className='card-height'>
                        <Card.Body>
                          <h5>Google</h5>

                          {createFormGroup(
                            'googleBusiness',
                            'Google Business Eintrag (URL)',
                            'text',
                          )}
                        </Card.Body>
                      </Card>

                      <Card className='card-height'>
                        <Card.Body>
                          <h5>Zertifikat</h5>

                          <SelectWithSearch
                            id="certificates_id"
                            label="Zertifikat"
                            placeholder="Suche..."
                            apiEndpoint="/certificates"
                            onChange={handleSelectChange}
                            initialValue={formValues.certificates_id ? { value: formValues.certificates_id.toString(), label: formValues.certificate?.title! } : null}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card className='card-height'>
                        <Card.Body>
                          <h5>Newplan</h5>
                          {
                            accounts && <DropdownWithAddOption
                              id="accountId"
                              label="Account"
                              options={Object.entries(accounts).map(([value, label]) => ({
                                value,
                                label,
                              }))}
                              initialValue={formValues.accountId.toString()}
                              onChange={(selectedOption) => {
                                setFormValues((prev) => ({
                                  ...prev,
                                  accountId: selectedOption || '',
                                }));
                              }}
                              onAddOption={handleAddNewAccount}
                              placeholder="Auswählen..."
                            />
                          }
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={12}>
                      <Card className='card-block card-stretch card-height'>
                        <Card.Body>
                          <h5>Vertragsinformationen</h5>
                          <ComboButtonGroup
                            buttons={comboButtonsContract}
                            selectedCombo={selectedCombo}
                            setSelectedCombo={setSelectedCombo}
                            borderRadius="normal"
                          ></ComboButtonGroup>
                          <div
                            style={{
                              display:
                                selectedCombo === 'location_contract'
                                  ? 'block'
                                  : 'none',
                            }}
                          >

                            <Row>
                              <Col lg={6}>                  {createSelectGroup(
                                'contractRentalStatus',
                                'Miet-Status',
                                Object.entries(ActiveInactiveMapping).map(([value, label]) => ({
                                  value,
                                  label,
                                })),
                                'Auswählen...',
                              )}
                                {createFormGroup('contractStart', 'Mietbeginn', 'date')}
                                {createFormGroup('contractEnd', 'Mietende', 'date')}
                              </Col>
                              <Col lg={6}>                            {createFormGroup('contractNoticePeriod', 'Kündigungsfrist', 'number', false, '(in Monaten)')}

                                {createFormGroup('contractDateOfTermination', 'Datum der Kündigung', 'date')}
                                {createFormGroup('contractMinRentalPeriod', 'Mindestmietdauer', 'number', false, '(in Monaten)')}</Col>
                            </Row>

                          </div>
                          <div
                            style={{
                              display:
                                selectedCombo === 'location_landlord'
                                  ? 'block'
                                  : 'none',
                            }}
                          >
                            <Row>
                              <Col lg={6}>{createFormGroup(
                                'landlordName',
                                'Name'
                              )}
                                {createFormGroup('landlordContactPerson', 'Ansprechpartner')}
                                {createFormGroup('landlordEmail', 'E-Mail')}
                              </Col>
                              <Col lg={6}>
                                {createFormGroup(
                                  'landlordIban',
                                  'IBAN'
                                )}
                                {createFormGroup('landlordIntendedUse', 'Verwendungszweck')}
                                {/* {createFormGroup('utilization', 'Standortnutzung')} */}</Col>
                            </Row>


                          </div>
                          <div
                            style={{
                              display:
                                selectedCombo === 'location_costs'
                                  ? 'block'
                                  : 'none',
                            }}
                          >
                            <Row>
                              <Col lg={6}>
                                {createFormGroup('costsDeposit', 'Kaution', 'text', false, '(in €)')}
                                {createFormGroup('costsFurtherRents', 'Weitere Mieten', 'text', false, '(zum Xten des Monats)')}
                                {createFormGroup('costsMonthlyNet', 'Miete monatlich, Netto', 'text', false, '(in €)')}
                                {createFormGroup('costsOfTermination', 'Kosten bei Kündigung', 'text', false, '(in €)')}
                                {createFormGroup('costsOneTime', 'Einmalige Kosten', 'text', false, '(in €)')}
                              </Col>
                              <Col lg={6}>
                                {createFormGroup('costsPaymentFirstRent', 'Bezahlung erste Miete', 'date')}

                                {paymentMethods && <DropdownWithAddOption
                                  id="costsPaymentMethod"
                                  label="Zahlart"
                                  options={Object.entries(paymentMethods).map(([value, label]) => ({
                                    value,
                                    label,
                                  }))}
                                  initialValue={formValues.costsPaymentMethod.toString()}
                                  onChange={(selectedOption) => {
                                    setFormValues((prev) => ({
                                      ...prev,
                                      costsPaymentMethod: selectedOption || '',
                                    }));
                                  }}
                                  onAddOption={handleAddPaymentMethod}
                                  placeholder="Auswählen..."
                                />}

                                {createFormGroup('costsRentIncrease', 'Mietsteigerung')}
                                {createSelectGroup(
                                  'costsTaxDuty',
                                  'Umsatzsteuerpflichtig',
                                  Object.entries(YesNoMapping).map(([value, label]) => ({
                                    value,
                                    label,
                                  })),
                                  'Auswählen...',
                                  true
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div
                            style={{
                              display: selectedCombo === 'location_document' ? 'block' : 'none',
                            }}
                          >
                            <div className='d-flex justify-content-end mb-3'>
                              <Button
                                className="btn-soft-primary"
                                onClick={() => {
                                  setShowContractMediaUploadModal(true);
                                }}
                              >
                                Medium auswählen
                              </Button>
                            </div>

                            <SelectedMediaPreview
                              selectedMediaForMultiSelect={formValues.contractMedia}
                              handleDeleteMedia={(media) => handleMediaDelete(media, 'contract-media')}
                              colProps={6}
                            />
                          </div>

                          <div
                            style={{
                              display: selectedCombo === 'location_images' ? 'block' : 'none',
                            }}
                          >
                            <div className='d-flex justify-content-end mb-3'>
                              <Button
                                className="btn-soft-primary"
                                onClick={() => {
                                  setShowImageMediaUploadModal(true);
                                }}
                              >
                                Medium auswählen
                              </Button>
                            </div>

                            <SelectedMediaPreview
                              selectedMediaForMultiSelect={formValues.imageMedia}
                              handleDeleteMedia={(media) => handleMediaDelete(media, 'image-media')}
                            />

                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body >
      </Modal >

      {showContractMediaUploadModal && (
        <MediaPickerMultiModal
          onSelectMedia={(media) => handleMediaSelect(media, 'contract-media')}
          initialMedia={formValues.contractMedia}
          initialFolderId={location?.folderId ?? 1}
          allowedMimeTypesUpload={['application/pdf', 'application/x-pdf']}
          allowedMimeTypesSelection={['application/pdf', 'application/x-pdf', 'inode/directory']}
          convention="locations-contract"
          onModalClose={() => {
            setShowContractMediaUploadModal(false);
          }}
        />
      )}

      {showImageMediaUploadModal && (
        <MediaPickerMultiModal
          onSelectMedia={(media) => handleMediaSelect(media, 'image-media')}
          initialMedia={formValues.imageMedia}
          initialFolderId={location?.folderId ?? 1}
          allowedMimeTypesUpload={['image/heic', 'image/heif', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml']}
          allowedMimeTypesSelection={['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml', 'inode/directory']}
          convention="locations-image"
          onModalClose={() => {
            setShowImageMediaUploadModal(false);
          }}
        />
      )}

      {showMainImageSelectionModal && <MediaPickerModal
        onSelectMedia={(media) => handleMediaSelect(media, 'main-image')}
        initialMedia={formValues.mainImage}
        initialFolderId={location?.folderId ?? 1}
        allowedMimeTypesSelection={['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml', 'inode/directory']}
        onModalClose={() => setShowMainImageSelectionModal(false)}
      >
      </MediaPickerModal>
      }

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditLocationModal;
