import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../products/GenericInfosCard';
import { CertificationZipValues, Setting } from '../../interfaces';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import EditDefaultValuesExcelCertificationModal from './modal/EditDefaultValuesExcelCertificationModal';

interface DefaultValuesExcelCertificationDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const DefaultValuesExcelCertificationDetails: React.FC<DefaultValuesExcelCertificationDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const certificationZipValues = setting?.details as CertificationZipValues
    
    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    function getInfo2() {
        const info2 = [
            {
                label: 'Schulungsfläche',
                value: certificationZipValues?.trainingArea || '-',
            },
            {
                label: 'Ausstattung',
                value: certificationZipValues?.equipment || '-',
            },
            {
                label: 'Geplante Teilnehmeranzahl',
                value: certificationZipValues?.plannedNumberOfParticipants || '-',
            },
        ];
        return info2;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={4} />
                    ) : (
                        <GenericInfosCard
                            title=""
                            infos={getInfo2()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
            </Row>
            {setting && showAddEditModal && <EditDefaultValuesExcelCertificationModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </EditDefaultValuesExcelCertificationModal>
            }
        </>
    );
};

export default DefaultValuesExcelCertificationDetails;
