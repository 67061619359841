import {
    faCloud,
    faCloudExclamation,
    faCloudSlash,
    faCircle,
    faCircleCheck,
    faCircleDashed,
    faBoxArchive,
    IconDefinition
} from '@fortawesome/pro-light-svg-icons';
import { faHighlighter, faEye, } from '@fortawesome/pro-solid-svg-icons';

export const statusCertificationIconMap: { [key: number]: IconDefinition } = {
    0: faCircle,
    1: faCircle,
    2: faCircleDashed,
    3: faCircleCheck,
};

export const statusCertificationColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-danger',
    2: 'text-warning',
    3: 'text-success',
};

export const statusIconMap: { [key: number]: IconDefinition } = {
    0: faCloudSlash,
    1: faCloud,
    2: faCloudExclamation,
    3: faBoxArchive,
};

export const statusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-success',
    2: 'text-warning',
    3: 'text-muted',
};

export const productTypeIconMap: { [key: number]: IconDefinition } = {
    1: faEye,
    2: faHighlighter,
};

export const productTypeColorMap: { [key: number]: string } = {
    1: 'text-success',
    2: 'text-info',
};