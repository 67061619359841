import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from 'react';
import { Company } from '../enum';
import { PermissionRights, Permissions } from '../../interfaces';

export const usePermissions = () => {
  const [permissions, setPermissions] = useState<Permissions>({
    companies: [],
  });
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setPermissionsLoaded(false);
    if (token) {
      try {
        const decodedToken = jwtDecode<{ views: Permissions }>(token);
        setPermissions(decodedToken.views || {});
      } catch (error) {
        console.error('Failed to decode token', error);
      }
    }
    setPermissionsLoaded(true);
  }, []);

  const userHasPermission = (viewKey: string): boolean => {
    return Object.keys(permissions).includes(viewKey);
  };

  const userHasPermissionByRight = (viewKey: string, right: keyof PermissionRights): boolean => {
    const viewPermission = permissions[viewKey as keyof Permissions] as PermissionRights | undefined;
    return viewPermission?.[right] === true;
  };

  const userHasCompanyAccess = (company: Company): boolean => {
    return permissions.companies.includes(company);
  };

  return { userHasPermissionByRight, userHasPermission, userHasCompanyAccess, permissionsLoaded, permissions };
};
