import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
import { MediaItem } from '../../interfaces';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';

interface SelectionActionsProps {
  selectedCount: number;
  selectedMedia: { [key: string]: boolean };
  handleDeSelectAll: () => void;
  onSubmitSuccess: () => void;
  media: MediaItem[];
  amountAllItems: number
}

const SelectionActions: React.FC<SelectionActionsProps> = ({
  selectedCount,
  selectedMedia,
  handleDeSelectAll,
  onSubmitSuccess,
  media,
  amountAllItems
}) => {
  const { userHasPermissionByRight } = usePermissions();
  const [isAllSelected, setIsAllSelected] = useState(false)

  const selectedMediaIds = Object.keys(selectedMedia)
    .filter(key => selectedMedia[key] === true)
    .map(Number);

  const anySelectedMediaSecure = selectedMediaIds.some(id => {
    const mediaItem = media.find(item => item.id === id);
    return mediaItem && mediaItem.locked;
  });

  return (
    <tr>
      <td colSpan={7}>
        <Row
          className="d-flex justify-content-between p-3 bg-light-blue"
        >
          <Col className="d-flex align-items-center" md={2}>
          <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
            <Button variant="link" onClick={handleDeSelectAll}>
              Auswahl aufheben
            </Button>
            {
              !isAllSelected && <Button hidden variant="link" className='ps-0' onClick={() => setIsAllSelected(true)}>
                Alle Auswählen ({amountAllItems})
              </Button>
            }
          </Col>
          <Col className="d-flex justify-content-end" md={8}>
            {!anySelectedMediaSecure && (
              <DeleteModal
                modalTitle="Löschen?"
                buttonName="Löschen"
                entityType='media'
                entityIds={selectedMediaIds}
                onSubmit={onSubmitSuccess}
                isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewMedia, 'delete')}>
              </DeleteModal>
            )}
          </Col>
        </Row>
      </td>
    </tr>
  );
};

export default SelectionActions;
