import {
  faCloud,
  faCloudExclamation,
  faCloudSlash,
  faCircle,
  faCircleCheck,
  faCircleDashed,
  faBoxArchive
} from '@fortawesome/pro-light-svg-icons';
import { CertificationStatus, ProductType, PublicationStatus, TypeOfLocation } from '../enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faUserCrown, faUser, faWebhook, faHighlighter, faEye, } from '@fortawesome/pro-solid-svg-icons';

export function getCertificationStatusColor(
  certification_status: CertificationStatus
): string {
  switch (Number(certification_status)) {
    case 0:
      return 'text-danger';
    case 1:
      return 'text-danger';
    case 2:
      return 'text-warning';
    case 3:
      return 'text-success';
    default:
      return '';
  }
}

export function getPublicationStatusColor(
  publication_status: PublicationStatus
): string {
  switch (Number(publication_status)) {
    case 0:
      return 'text-danger';
    case 2:
      return 'text-warning';
    case 1:
      return 'text-success';
    case 3:
      return 'text-muted';
    default:
      return '';
  }
}

export function getIconByPublicationStatus(
  publication_status: PublicationStatus
) {
  let iconComponent;

  switch (Number(publication_status)) {
    case 1:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-success)' }}
          icon={faCloud}
        />
      );
      break;
    case 0:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-danger)' }}
          icon={faCloudSlash}
        />
      );
      break;
    case 2:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-warning)' }}
          icon={faCloudExclamation}
        />
      );
      break;
    case 3:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-muted)' }}
          icon={faBoxArchive}
        />
      );
      break;
    default:
      break;
  }

  return iconComponent;
}

export function getIconByCertificationStatus(
  certification_status: CertificationStatus
) {
  let iconComponent;

  switch (Number(certification_status)) {
    case 3:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-success)' }}
          icon={faCircleCheck}
        />
      );
      break;
    case 2:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-warning)' }}
          icon={faCircleDashed}
        />
      );
      break;
    case 1:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-danger)' }}
          icon={faCircle}
        />
      );
      break;
    case 0:
      iconComponent = (
        <FontAwesomeIcon
          width={15}
          className="me-2"
          style={{ color: 'var(--bs-danger)' }}
          icon={faCircle}
        />
      );
      break;
    default:
      break;
  }

  return iconComponent;
}

export function getIconByLocationType(
  type: TypeOfLocation
) {
  let iconComponent;

  switch (Number(type)) {
    case 1:
      iconComponent = (
        <FontAwesomeIcon icon={faUserCrown} width={15} className='me-2' style={{ color: 'var(--bs-success)' }} />
      );
      break;
    case 2:
      iconComponent = (
        <FontAwesomeIcon icon={faHandshake} width={15} className='me-2' style={{ color: 'var(--bs-info' }} />
      );
      break;
    default:
      break;
  }

  return iconComponent;
}

export function getIconByProductType(
  type: ProductType
) {
  let iconComponent;

  switch (Number(type)) {
    case 1:
      iconComponent = (
        <FontAwesomeIcon icon={faEye} width={15} className='me-2' style={{ color: 'var(--bs-success)' }} />
      );
      break;
    case 2:
      iconComponent = (
        <FontAwesomeIcon icon={faHighlighter} width={15} className='me-2' style={{ color: 'var(--bs-info' }} />
      );
      break;
    default:
      break;
  }

  return iconComponent;
}


export function getIconByUserRole(
  role: number
) {
  let iconComponent;

  switch (Number(role)) {
    case 3:
      iconComponent = (
        <FontAwesomeIcon icon={faWebhook} width={15} className='me-2' />
      );
      break
    case 1:
      iconComponent = (
        <FontAwesomeIcon icon={faUserCrown} width={15} className='me-2' />
      );
      break;
    case 2:
      iconComponent = (
        <FontAwesomeIcon icon={faUser} width={15} className='me-2' />
      );
      break;
    default:
      break;
  }

  return iconComponent;
}