import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import DeleteModal from './DeleteModal';
import { usePermissions } from '../utils/hooks/usePermissions';

interface SelectionActionsProps {
    selectedCount: number;
    selectedItems: any;
    handleDeSelectAll: () => void;
    onSubmitSuccess: () => void;
    amountAllItems: number;
    entityType: string
}

const GeneralSelectionActions: React.FC<SelectionActionsProps> = ({
    selectedCount,
    selectedItems,
    handleDeSelectAll,
    onSubmitSuccess,
    amountAllItems,
    entityType
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [isAllSelected, setIsAllSelected] = useState(false)
    return (
        <tr>
            <td colSpan={7}>
                <Row
                    className="d-flex justify-content-between p-3 bg-light-blue"
                >
                    <Col className="d-flex align-items-center" md={2}>
                        <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
                        <Button variant="link" onClick={handleDeSelectAll}>
                            Auswahl aufheben
                        </Button>
                        {
                            !isAllSelected && <Button hidden variant="link" className='ps-0' onClick={() => setIsAllSelected(true)}>
                                Alle Auswählen ({amountAllItems})
                            </Button>
                        }
                    </Col>
                    <Col className="d-flex justify-content-end" md={8}>
                        <DeleteModal
                            modalTitle="Löschen?"
                            buttonName="Löschen"
                            entityType={entityType}
                            entityIds={Object.keys(selectedItems)
                                .filter(key => selectedItems[key] === true)
                                .map(Number)}
                            onSubmit={onSubmitSuccess}
                            isDisabled={!userHasPermissionByRight(entityType, 'delete')}>
                        </DeleteModal>
                    </Col>
                </Row>
            </td>
        </tr>
    );
};

export default GeneralSelectionActions;
