import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { Certificate } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import CertificateDetails from '../../components/certificates/detail/CertificateDetails';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

function CertificateDetail() {
  const { certificateId } = useParams();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/certificates')
  const { show, message, error, showToast, hideToast } = useToast();
  const [certificate, setCertificate] = useState<Certificate>();
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewCertificates, 'read');

      if (hasPermission) {
        certificateId && fetchCertificate(certificateId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [certificateId, permissionsLoaded]);

  const fetchCertificate = async (certificateId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/certificates/${certificateId}`);
      const certificateResponse = response.data;
      setCertificate(certificateResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false)
      setHttpError(error)
     }
  };

  const handleModalSubmit = () => {
    setCertificate(undefined)
    certificateId && fetchCertificate(certificateId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <CertificateDetails certificate={certificate} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></CertificateDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default CertificateDetail;
