import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Location } from '../../../interfaces';
import { ActiveInactiveMapping, getEnumValue } from '../../../utils/enum';
import { formatDateWithoutTime } from '../../../utils/utils';

interface ContractDetailsProps {
  isLoading?: boolean;
  location?: Location;
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  isLoading,
  location,
}) => {
  function getContractInfo() {
    const contractInfo = [
      {
        label: 'Miet-Status',
        value: getEnumValue(ActiveInactiveMapping, location?.contractRentalStatus?.toString()),
      },
      {
        label: 'Mietbeginn',
        value: location?.contractStart ? formatDateWithoutTime(location?.contractStart) : '-',
      },
      {
        label: 'Mietende',
        value: location?.contractEnd ? formatDateWithoutTime(location?.contractEnd) : '-',
      },
      {
        label: 'Kündigungsfrist',
        value: location?.contractNoticePeriod? `${location?.contractNoticePeriod?.toString()} Monat${location?.contractNoticePeriod > 1 ? 'e': ''}` : '-',
      },
      {
        label: 'Datum der Kündigung',
        value: location?.contractDateOfTermination ? formatDateWithoutTime(location?.contractDateOfTermination) : '-',
      },
      {
        label: 'Mindestmietdauer',
        value: location?.contractMinRentalPeriod? `${location?.contractMinRentalPeriod?.toString()} Monat${location?.contractMinRentalPeriod > 1 ? 'e': ''}` : '-',
      },
    ];
    return contractInfo;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <GenericInfosCard
            title="Vertragsdaten"
            infos={getContractInfo()}
          ></GenericInfosCard>
        </Col>
      </Row>
    </>
  );
};

export default ContractDetails;
