import { FieldConfig } from "../../interfaces";
import { getFieldConfigByResourceName } from "../../utils/utils";

const defaultRenderHeader = (
  columnKey: string,
  fieldConfigs: FieldConfig[],
  requestSort: (key: string) => void,
  getSortCaret: (columnField: string) => JSX.Element | null,
) => {
  // Find the fieldConfig that matches the columnKey (resourceName)
  const fieldConfig = getFieldConfigByResourceName(fieldConfigs, columnKey);
  const sortField = fieldConfig?.resourceName || columnKey;

  return (
    <th
      key={sortField}
      className="cursor-pointer position-relative table-cell-wrap"
      scope="col"
      onClick={() => requestSort(sortField)}
      title={fieldConfig?.fieldLabel || columnKey}
    >
      {fieldConfig?.fieldLabel || columnKey}
      <div className="position-absolute" style={{ right: 0, top: '8px' }}>
        {getSortCaret(columnKey)}
      </div>
    </th>
  );
};

export { defaultRenderHeader };