import { memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import OptimizationCard, { Item } from '../../components/optimization/OptimizationCard';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { useNavigate } from 'react-router-dom';
import { PermissionsEnum } from '../../utils/enum';
import Card from '../../components/bootstrap/card';

const exampleItemsThema: Item[] = [
  {
    id: '1',
    title: 'Agiles Projektmanagement',
    sichtbarkeit: '80%',
    status: 'active',
  },
  {
    id: '2',
    title: 'Softwareentwicklung',
    sichtbarkeit: '70%',
    status: 'active',
  },
  { id: '3', title: 'UI/UX Design', sichtbarkeit: '60%', status: 'draft' },
  { id: '7', title: 'API-Integration', sichtbarkeit: '-', status: 'draft' },
  { id: '5', title: 'Cloud-Migration', sichtbarkeit: '-', status: 'active' },
  { id: '6', title: 'Sicherheitsaudit', sichtbarkeit: '-', status: 'draft' },
  { id: '7', title: 'API-Integration', sichtbarkeit: '-', status: 'draft' },
];

const exampleItemsUnterThema: Item[] = [
  {
    id: '1',
    title: 'Agiles Projektmanagement',
    sichtbarkeit: '80%',
    status: 'active',
  },
  {
    id: '2',
    title: 'Softwareentwicklung',
    sichtbarkeit: '70%',
    status: 'active',
  },
  { id: '3', title: 'UI/UX Design', sichtbarkeit: '60%', status: 'draft' },
  {
    id: '4',
    title: 'Datenbankoptimierung',
    sichtbarkeit: '90%',
    status: 'draft',
  },
  { id: '5', title: 'Cloud-Migration', sichtbarkeit: '-', status: 'active' },
  { id: '6', title: 'Sicherheitsaudit', sichtbarkeit: '-', status: 'draft' },
  { id: '7', title: 'API-Integration', sichtbarkeit: '-', status: 'draft' },
];

const exampleItemsBereich: Item[] = [
  {
    id: '1',
    title: 'Agiles Projektmanagement',
    sichtbarkeit: '80%',
    status: 'active',
  },
  {
    id: '2',
    title: 'Softwareentwicklung',
    sichtbarkeit: '70%',
    status: 'active',
  },
  { id: '3', title: 'UI/UX Design', sichtbarkeit: '60%', status: 'draft' },
  {
    id: '4',
    title: 'Datenbankoptimierung',
    sichtbarkeit: '90%',
    status: 'draft',
  },
  { id: '5', title: 'Cloud-Migration', sichtbarkeit: '-', status: 'active' },
  { id: '6', title: 'Sicherheitsaudit', sichtbarkeit: '-', status: 'draft' },
  { id: '9', title: 'API-Integration', sichtbarkeit: '-', status: 'draft' },
];

const Optimization: React.FC = memo(() => {
  const [topics, setTopics] = useState<Item[]>(exampleItemsThema);
  const [areas, setAreas] = useState<Item[]>(exampleItemsBereich);
  const [focuses, setFocuses] = useState<Item[]>(exampleItemsUnterThema);

  const [selectedTopic, setSelectedTopic] = useState<Item>();
  const [selectedArea, setSelectedArea] = useState<Item>();
  const [selectedFocus, setSelectedFocus] = useState<Item>();
  const { userHasPermission, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermission(PermissionsEnum.ViewOptimization);

      if (!hasPermission) {
        navigate('/errors/error404');
      }
    }
  }, [permissionsLoaded]);

  const handleTopicSelection = (topic: Item) => {
    setSelectedTopic(topic);
    setSelectedArea(undefined);
    setSelectedFocus(undefined);
  };

  const handleSubtopicSelection = (subtopic: Item) => {
    setSelectedArea(subtopic);
    setSelectedFocus(undefined);
  };

  const handleAreaSelection = (area: Item) => {
    setSelectedFocus(area);
  };

  const getTopics = () => {
    //fetch topics
    //setTopics()
  };

  const getAreas = () => {
    //fetch areas
    //setAreas()
  };

  const getFocuses = () => {
    //fetch main focuses
    //setFocuses ()
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Optimierung der Sichtbarkeit</h3>
      </div>
      <Row>
        <Col lg={4} className="overflow-auto" style={{ maxHeight: '500px' }}>
          <OptimizationCard
            type="topic"
            items={topics}
            onSelection={handleTopicSelection}
            selectedItem={selectedTopic}
          />
        </Col>

        <Col lg={4} className="overflow-auto" style={{ maxHeight: '500px' }}>
          {selectedTopic ? (
            <OptimizationCard
              type="area"
              items={areas}
              onSelection={handleSubtopicSelection}
              selectedItem={selectedArea}
            />
          ) : (
            <Card className="h-100 m-0 p-0" style={{ background: 'white', border: '3px dotted #adb5bd' }}></Card>
          )}
        </Col>

        <Col lg={4} className="overflow-auto" style={{ maxHeight: '500px' }}>
          {selectedArea ? (
            <OptimizationCard
              type="focus"
              items={focuses}
              onSelection={handleAreaSelection}
              selectedItem={selectedFocus}
            />
          ) : (
            <Card className="h-100 m-0 p-0" style={{ background: 'white', border: '3px dotted #adb5bd' }}></Card>
          )}
        </Col>
      </Row>
    </>
  );
});

export default Optimization;
