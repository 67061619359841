import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export type ComboButtonId = string;

export interface ComboButton {
  id: ComboButtonId;
  label: string;
  disabled?: boolean;
  disabledText?: string;
  error?: boolean
}

interface ComboButtonGroupProps {
  buttons: ComboButton[];
  selectedCombo: ComboButtonId | '';
  setSelectedCombo: (value: ComboButtonId | '') => void;
  borderRadius?: 'pill' | 'normal';
}

const ComboButtonGroup: React.FC<ComboButtonGroupProps> = ({
  buttons,
  selectedCombo,
  setSelectedCombo,
  borderRadius = 'pill',
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>
  ) => {
    if ('target' in event && event.target instanceof HTMLInputElement) {
      setSelectedCombo(event.target.id);
    }
  };

  const getButtonClass = (id: ComboButtonId) => {
    return `btn ${selectedCombo === id ? 'btn-soft-primary' : ''}`;
  };

  const renderButton = ({ id, label, disabled, disabledText, error }: ComboButton) => {
    const button = (
      <React.Fragment key={id}>
        <input
          type="radio"
          className="d-none"
          name="btnradio"
          id={id}
          autoComplete="off"
          onChange={handleChange}
          onClick={handleChange}
          disabled={disabled}
        />
        <label
          style={{ fontSize: '12px' }}
          className={`${getButtonClass(id)} mb-2 me-2 btn-sm ${
            borderRadius === 'pill' ? 'rounded-pill' : 'rounded'
          } ${error && 'text-danger'}`}
          htmlFor={id}
        >
          {label}
        </label>
      </React.Fragment>
    );

    if (disabled && disabledText) {
      return (
        <OverlayTrigger
          key={id}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-disabled-${id}`}>{disabledText}</Tooltip>
          }
        >
          <span style={{ cursor: 'not-allowed' }}>{button}</span>
        </OverlayTrigger>
      );
    }

    return button;
  };

  return <div>{buttons.map((button) => renderButton(button))}</div>;
};

export default ComboButtonGroup;
