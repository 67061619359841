import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { useSortableData } from '../../utils/hooks/useSortableData';
import { useSelection } from '../../utils/hooks/useSelection';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { User, UserRole } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import AddEditUsersModal from '../../components/users/modal/AddEditUsersModal';
import { getUserStatusIcon } from '../../utils/users/utils';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import Card from '../../components/bootstrap/card';
import SearchInput from '../../components/SearchInput';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

interface UsersResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: User[];
  searchFilters: string[];
}

interface UsersRoleResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: UserRole[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];


const Users = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedUsers, requestSort } = useSortableData(users, false, { field: 'title', type: 'asc' });
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const getId = useCallback((user: User) => user.id, []);

  const {
    selectedItems: selectedUsers,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(users, getId);

  const fetchUsers = useCallback(async () => {
    setUsers([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      const response = await ApiClient.get(`/users${queryParams}`);
      const usersResponse = response.data as UsersResponse;
      setTotalPages(usersResponse.amountPages);
      setUsers(usersResponse.list ?? []);
      setCurrentPage(usersResponse.page);
      setLimit(usersResponse.itemsPerPage);
      setTotalEntries(usersResponse.amountAllItems);
      setAvailableFilter(usersResponse.searchFilters)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, selectedCombo, searchQuery, limit]);

  const fetchUserRoles = async () => {
    const res = await ApiClient.get('/roles')
    const userRoles = res.data as UsersRoleResponse
    setUserRoles(userRoles.list ?? []);
  }

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewUsers, 'read');

      if (hasPermission) {
        fetchUsers();
        fetchUserRoles()
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    searchQuery,
    selectedCombo,
    fetchUsers,
    permissionsLoaded,
    location,
    limit
  ]);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const handleUsersUpdateSubmit = () => {
    fetchUsers();
  };

  const handleComboSelect = (newCombo: ComboButtonId) => {
    setSelectedCombo(newCombo);
    setCurrentPage(1);
  };

  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Nutzer Verwaltung</h3>

        <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewUsers, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
          Benutzer hinzufügen
        </Button>

      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={handleComboSelect}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
          <Row>
            <Col>

            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewUsers, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewUsers, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Name
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('email')}
              >
                E-Mail-Adresse
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('roles_id')}
              >
                Benutzergruppe
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('status')}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedUsers}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleUsersUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='users'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedUsers.map((user) => (
                <tr key={user.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewUsers, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewUsers, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedUsers[user.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(user.id, e)
                      }}
                    />

                    <Link
                      to={`/${companyId}/users/${user.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {user.title}
                    </Link>
                  </td>
                  <td>{user.email || '-'}</td>
                  <td>{userRoles.find(r => r.id.toString() === user.roles_id.toString())?.title}</td>
                  <td className='text-center'>
                    {getUserStatusIcon(user.status)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedUsers.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Benutzer gefunden</p>
          </div>
        )}
      </div>

      {totalEntries > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      {showAddEditModal && userRoles && <AddEditUsersModal
        modalTitle="Benutzer hinzufügen"
        onSubmitSuccess={handleUsersUpdateSubmit}
        userRoles={userRoles}
        onModalClose={() => setShowAddEditModal(false)}
      ></AddEditUsersModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Users;
