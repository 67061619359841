import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AddEditLocationModal from '../../components/locations/modal/AddEditLocationModal';
import ImportantInfosCard from '../../components/locations/ImportantInfosCard';
import {
  CertificationStatus,
  Countries,
  GermanStates,
  PermissionsEnum,
  PublicationStatus,
  getEnumValue,
} from '../../utils/enum';
import GoogleInfosCard from '../../components/locations/GoogleInfosCard';
import NewPlanInfosCard from '../../components/locations/NewPlanInfosCard';
import SkeletonCard from '../../components/locations/skeleton/SkeletonCard';
import { ApiClient } from '../../services/ApiClient';
import PublicationStatusDropdown from '../../components/PublicationStatusDropdown';
import { AccountId, Location, Partner, PaymentMethods } from '../../interfaces';
import ContractDetails from '../../components/locations/detail/ContractDetails';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import ComboButtonGroup from '../../components/ComboButtonGroup';
import LandlordDetails from '../../components/locations/detail/LandlordDetails';
import CostsDetails from '../../components/locations/detail/CostsDetails';
import MediaDetails from '../../components/locations/detail/MediaDetails';
import { getMediaUrl } from '../../utils/media/utils';
import GenericInfosCard from '../../components/products/GenericInfosCard';
import CertificationStatusDropdown from '../../components/CertificationStatusDropdown';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

const comboButtons = [
  { id: 'contract', label: 'Vertrag' },
  { id: 'documents', label: 'Dokumente' },
  { id: 'images', label: 'Bilder' },
  { id: 'landlord', label: 'Vermieter' },
  { id: 'costs', label: 'Kosten' },
];

function LocationDetail() {
  const { locationId } = useParams();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/locations')
  const { show, message, error, showToast, hideToast } = useToast();
  const [location, setLocation] = useState<Location>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCombo, setSelectedCombo] =
    useState('contract');
  const [accounts, setAccounts] = useState<AccountId>();
  const [partner, setPartner] = useState<Partner>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>();
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const companyId = localStorage.getItem('companyId') || 'oc';

  const address = `${location?.street} ${location?.streetNr} <br/> ${location?.zipcode} ${location?.city} <br/> ${location?.stateLabel}, ${location?.countryLabel}`;

  async function handlePublicationStatusUpdate(
    publicationStatus: PublicationStatus
  ) {
    try {
      await ApiClient.put(`/locations/${locationId}`, {
        status: publicationStatus,
      });
      locationId && fetchLocation(locationId);
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  }

  async function handleCertificationStatusUpdate(
    certificationStatus: CertificationStatus
  ) {
    try {
      await ApiClient.put(`/locations/${locationId}`, {
        statusCertification: certificationStatus,
      });
      locationId && fetchLocation(locationId);
      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  }

  const fetchAccounts = async () => {
    const res = await ApiClient.get('/settings?ident=meinNowAccounts')
    setAccounts(res.data.list[0].details || {})
  }

  const fetchPartner = async () => {
    const res = await ApiClient.get(`/settings?ident=locationsPartners`)
    setPartner(res.data.list[0].details || {})
  }

  const fetchPaymentMethods = async () => {
    const res = await ApiClient.get(`/settings?ident=paymentMethods`)
    setPaymentMethods(res.data.list[0].details || {})
  }

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewLocations, 'read');

      if (hasPermission) {
        locationId && fetchLocation(locationId);
        fetchAccounts()
        fetchPartner()
        fetchPaymentMethods()
      } else {
        navigate('/errors/error404');
      }
    }
  }, [locationId, permissionsLoaded]);

  const fetchLocation = async (locationId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/locations/${locationId}`);
      const locationResponse = response.data;
      setLocation(locationResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHttpError(error)
    }
  };

  const handleModalSubmit = () => {
    setLocation(undefined)
    locationId && fetchLocation(locationId);
  };

  const getLocationMainImage = (location?: Location) => {
    const mainImage = location?.mainImage

    if (mainImage) {
      return getMediaUrl(mainImage)
    }

    return 'https://storage.onecareer.de/placeholder-location.webp'
  }

  return (
    <Fragment>
      <Row>
        <Col lg={3}>
          {isLoading ? (
            <SkeletonCard sectionCount={10} imageFirst includeImage />
          ) : (
            <ImportantInfosCard
              imageSrc={
                getLocationMainImage(location)
              }
              partnerName={getEnumValue(partner ?? {}, location?.partner)}
              lidIdent={location?.lidIdent || '-'}
              address={address || '-'}
              phone={location?.telephone || '-'}
              fax={location?.fax || '-'}
              mobile={location?.mobile || '-'}
              email={location?.email || '-'}
              title={location?.title || '-'}
              version={location?.version.toString() || "-"}
            />
          )}
        </Col>
        <Col lg={9}>
          <Card className="card-block card-stretch">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                <h4 >Wichtige Informationen</h4>
                {location && (
                  <div className="d-flex">
                    <CertificationStatusDropdown
                      statusCertification={location?.statusCertification!}
                      handleCertificationStatusUpdate={
                        handleCertificationStatusUpdate
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewLocations, 'write')}
                    />

                    <PublicationStatusDropdown
                      status={location?.status!}
                      handlePublicationStatusUpdate={
                        handlePublicationStatusUpdate
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewLocations, 'write')}
                      style={{ backgroundColor: '#F9F9F9' }}
                    />

                    <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewLocations, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                      Bearbeiten
                    </Button>
                  </div>
                )}
              </div>

              <div className="horizontal-line mb-3"></div>

              <Row>
                <Col lg={6}>
                  {isLoading ? (
                    <SkeletonCard sectionCount={3} includeImage />
                  ) : (
                    <GoogleInfosCard
                      googleBusinessEntry={location?.googleBusiness || '-'}
                      latitude={location?.googleLat || ''}
                      longitude={location?.googleLong || '-'}
                    ></GoogleInfosCard>
                  )}
                </Col>
                <Col lg={6}>
                  {isLoading ? (
                    <SkeletonCard sectionCount={5} />
                  ) : (
                    <NewPlanInfosCard
                      title={location?.titleNewplan || '-'}
                      street={location?.streetNewplan || '-'}
                      federalState={getEnumValue(GermanStates, location?.state)}
                      country={getEnumValue(Countries, location?.country)}
                      account={getEnumValue(accounts ?? {}, location?.accountId)}
                    ></NewPlanInfosCard>
                  )}
                </Col>
                <Col lg={6}>
                  {isLoading ? (
                    <SkeletonCard sectionCount={2} />
                  ) : (
                    <GenericInfosCard
                      title="Zertifikat"
                      infos={[
                        {
                          label: 'Titel',
                          value: location?.certificate ? `<a style="color: black; text-decoration: underline" href="/${companyId}/certificates/${location?.certificates_id}">${location?.certificate.title}</a>` : '-',
                          isMultiline: true
                        },
                        {
                          label: 'Zertifikatsnummer',
                          value: location?.certificate?.number || '-',
                        },
                        {
                          label: 'Zertifizierer',
                          value: location?.certificate?.certifier || '-',
                        },
                      ]}
                      firstColumnSize={4}
                    />
                  )}
                </Col>

              </Row>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                <div>
                  <h4>Vertragsinformationen</h4>
                </div>
                <div></div>
              </div>

              <div className="horizontal-line mb-3"></div>

              <ComboButtonGroup
                buttons={comboButtons}
                selectedCombo={selectedCombo}
                setSelectedCombo={setSelectedCombo}
                borderRadius="normal"
              ></ComboButtonGroup>

              {selectedCombo === 'contract' && (
                <ContractDetails location={location}></ContractDetails>

              )}
              {selectedCombo === 'documents' && (
                <MediaDetails title={location?.title} mediaItems={location?.contractMedia} ></MediaDetails>
              )}
              {selectedCombo === 'images' && (
                <MediaDetails title={location?.title} mediaItems={location?.imageMedia} ></MediaDetails>
              )}
              {selectedCombo === 'landlord' && (
                <LandlordDetails location={location}></LandlordDetails>
              )}
              {selectedCombo === 'costs' && (
                <CostsDetails paymentMethods={paymentMethods} location={location}></CostsDetails>
              )}

            </Card.Body>
          </Card>

        </Col>
      </Row>

      {showAddEditModal && <AddEditLocationModal
        modalTitle="Standort bearbeiten"
        location={location}
        onSubmitSuccess={handleModalSubmit}
        accounts={accounts}
        onAccountsUpdate={fetchAccounts}
        onPartnerUpdate={fetchPartner}
        partner={partner}
        paymentMethods={paymentMethods}
        onPaymentMethodsUpdate={fetchPaymentMethods}
        onModalClose={() => setShowAddEditModal(false)}
      ></AddEditLocationModal>}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default LocationDetail;
