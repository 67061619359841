import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { ApiClient } from '../../services/ApiClient';
import { Bdks } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import BdksDetails from '../../components/bdks/detail/BdksDetails';
import useErrorHandling from '../../utils/hooks/useErrorHandling';

function BdksDetail() {
  const { bdksId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [httpError, setHttpError] = useState<any>(null);
  useErrorHandling(httpError, '/bdks')
  const [bdks, setBdks] = useState<Bdks | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewBdks, 'read');

      if (hasPermission) {
        bdksId && fetchBdks(bdksId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [bdksId, permissionsLoaded]);

  const fetchBdks = async (bdksId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/bdks/${bdksId}`);
      const settingResponse = response.data;
      setBdks(settingResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false)
      setHttpError(error)
    }
  };

  const handleModalSubmit = () => {
    setBdks(null)
    bdksId && fetchBdks(bdksId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <BdksDetails bdks={bdks} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></BdksDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default BdksDetail;
