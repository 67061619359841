import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { User, UserRole } from '../../interfaces';
import UserDetails from '../../components/users/detail/UserDetails';

function UserDetail() {
  const { userId } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [user, setUser] = useState<User>();
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();


  const fetchUserRoles = async () => {
    const res = await ApiClient.get('/roles')
    const userRoles = res.data
    setUserRoles(userRoles.list ?? []);
  }

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewUsers, 'read');

      if (hasPermission) {
        userId && fetchUser(userId);
        fetchUserRoles()
      } else {
        navigate('/errors/error404');
      }
    }
  }, [userId, permissionsLoaded]);

  const fetchUser = async (userId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/users/${userId}`);
      const certificateResponse = response.data;
      setUser(certificateResponse);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setUser(undefined)
    userId && fetchUser(userId);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <UserDetails userRoles={userRoles} user={user} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></UserDetails>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default UserDetail;
