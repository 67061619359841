import { RoleStatus } from "../enum";
import { Permissions } from "../../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faGraduationCap,
  faGear,
  faImage,
  faLocationDot,
  faWrench,
  faArrowDownShortWide,
  faBarcode,
  faUser,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { faFileCertificate, faUsers, faWebhook, faShieldKeyhole, faKey, faLock, faUnlock, faArrowsToCircle, faListCheck } from '@fortawesome/pro-solid-svg-icons';

export function getRoleStatusIcon(
  status: RoleStatus
) {
  let iconComponent;
  switch (Number(status)) {
    case 0:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          style={{ color: 'var(--bs-danger)' }}
          icon={faLock}
        />
      );
      break;
    case 1:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          style={{ color: 'var(--bs-success)' }}
          icon={faUnlock}
        />
      );
      break;
    default:
      break
  }
  return iconComponent;
}

export function getModuleIcon(role: keyof Permissions) {
  let iconComponent;

  switch (role) {
    case 'bdks':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faBarcode}
          width={15}
        />
      );
      break;
    case 'certificates':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faFileCertificate}
          width={15}
        />
      );
      break;
    case 'dashboard':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faHouse}
          width={15}
        />
      );
      break;
    case 'locations':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faLocationDot}
          width={15}
        />
      );
      break;
    case 'products':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faGraduationCap}
          width={15}
        />
      );
      break;
    case 'media':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faImage}
          width={15}
        />
      );
      break;
    case 'systematics':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faArrowDownShortWide}
          width={15}
        />
      );
      break;
    case 'settings':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faGear}
          width={15}
        />
      );
      break;
    case 'users':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faUser}
          width={15}
        />
      );
      break;
    case 'roles':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faUsers}
          width={15}
        />
      );
      break;
    case 'optimization':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faWrench}
          width={15}
        />
      );
      break;
    case 'webhooks':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faWebhook}
          width={15}
        />
      );
      break;
    case 'apikeys':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faShieldKeyhole}
          width={15}
        />
      );
      break;
    case 'keywords':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faKey}
          width={15}
        />
      );
      break;
    case 'focuses':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faArrowsToCircle}
          width={15}
        />
      );
      break;  
    case 'tasks':
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faListCheck}
          width={15}
        />
      );
      break;
    default:
      iconComponent = (
        <FontAwesomeIcon
          className="me-2"
          icon={faTags}
          width={15}
        />
      );
      break;
  }

  return iconComponent;
}