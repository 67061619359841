import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GenericInfosCard from '../products/GenericInfosCard';
import { PaymentMethods, Setting } from '../../interfaces';
import SkeletonCard from '../locations/skeleton/SkeletonCard';
import Card from '../bootstrap/card';
import PaymentMethodsModal from './modal/PaymentMethodsModal';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';

interface PaymentMethodsDetailsProps {
    isLoading?: boolean;
    setting?: Setting | null
    onSubmitSuccess: (settingsId: number) => void;
}

const PaymentMethodsDetails: React.FC<PaymentMethodsDetailsProps> = ({
    isLoading,
    setting,
    onSubmitSuccess
}) => {
    const { userHasPermissionByRight } = usePermissions();
    const [showAddEditModal, setShowAddEditModal] = useState(false);

    const paymentMethods = setting?.details as PaymentMethods

    function getInfo1() {
        const info1 = [
            {
                label: 'Titel / Bezeichnung',
                value: setting?.title || '-',
            },
            {
                label: 'Ident / Kürzel',
                value: setting?.ident || '-',
            },
            {
                label: 'Beschreibung',
                value: setting?.description || '-',
            },
        ];
        return info1;
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <h4>{setting?.title}</h4>
                <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewSettings, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
                    Bearbeiten
                </Button>
            </div>
            <Row>
                <Col lg={6}>
                    {isLoading ? (
                        <SkeletonCard sectionCount={2} />
                    ) : (
                        <GenericInfosCard
                            title="Allgemein"
                            infos={getInfo1()}
                            firstColumnSize={4}
                        />
                    )}
                </Col>
                <Col lg={6}>
                    <Card className={`card-block card-stretch card-height`}>
                        <Card.Body>
                            <h5>Zahlarten</h5>
                            <ul className='list-no-dots'>
                                {paymentMethods && Object.keys(paymentMethods).length > 0
                                    ? Object.entries(paymentMethods).map(([key, value]) => (
                                        <li key={key}>{value}</li>
                                    ))
                                    : <li>Keine Zahlarten vorhanden</li>}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {setting && showAddEditModal && <PaymentMethodsModal
                modalTitle={`${setting.title} bearbeiten`}
                setting={setting}
                onSubmitSuccess={onSubmitSuccess}
                onModalClose={() => setShowAddEditModal(false)}
            >
            </PaymentMethodsModal>
            }
        </>
    );
};

export default PaymentMethodsDetails;
