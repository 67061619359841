import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GenericInfosCard from '../../products/GenericInfosCard';
import { Location } from '../../../interfaces';
import { formatIban } from '../../../utils/utils';

interface LandlordDetailsProps {
  isLoading?: boolean;
  location?: Location;
}

const LandlordDetails: React.FC<LandlordDetailsProps> = ({
  isLoading,
  location,
}) => {
  function getLandlordInfo() {
    const landlordInfo = [
      {
        label: 'Name',
        value: location?.landlordName || '-',
      },
      {
        label: 'Ansprechpartner',
        value: location?.landlordContactPerson || '-',
      },
      {
        label: 'E-Mail',
        value: location?.landlordEmail || '-',
      },
      {
        label: 'IBAN',
        value: location?.landlordIban ? formatIban(location?.landlordIban) : '-',
      },
      {
        label: 'Verwendungszweck',
        value: location?.landlordIntendedUse || '-',
      },
      {
        label: 'Standortnutzung',
        //value: location?.utilization.toString() || '-',
        value: '-'
      },
    ];
    return landlordInfo;
  }

  return (
    <>
      <Row>
        <Col lg={6}>
          <GenericInfosCard
            title="Vermieter"
            infos={getLandlordInfo()}
          ></GenericInfosCard>
        </Col>
      </Row>
    </>
  );
};

export default LandlordDetails;
