import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { MediaItem } from '../../../interfaces';
import { formatSizeUnits, getImageUrl } from '../../../utils/media/utils';
import MediaViewerModal from '../../MediaViewerModal';

interface MediaDetailsProps {
  isLoading?: boolean;
  mediaItems?: MediaItem[]
  title?: string
}

const MediaDetails: React.FC<MediaDetailsProps> = ({
  isLoading,
  mediaItems = [],
  title
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

  const handleShowModal = (index: number) => {
    setCurrentMediaIndex(index);
    setModalShow(true);
  };

  return (
    <>
      <MediaViewerModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        media={mediaItems}
        currentMediaIndex={currentMediaIndex}
        setCurrentMediaIndex={setCurrentMediaIndex}
        title={title}
      />
      <Row className='mt-2'>
        {mediaItems.length === 0 ? (
          <Col className="mb-2">
            <div
              className="d-flex justify-content-center align-items-center border rounded my-3 py-3"
            >
              <p className="p-0 m-0">Keine Medien zugewiesen</p>
            </div>
          </Col>
        ) :
          mediaItems.map((media, index) => (
            <Col key={index} lg={6} className="mb-2">
              <a
                href={`/${localStorage.getItem('companyId') || 'oc'}/media/${media.folderId}/${media.id}`}
                target="_blank"
                rel="noreferrer"
                className='text-black'
                style={{ color: 'inherit' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleShowModal(index);
                }}
              >
                <div className="d-flex justify-content-between align-items-center border rounded p-2 media-picker-preview-wrapper">
                  <div className="media-info d-flex align-items-center">
                    <img
                      src={getImageUrl(media)}
                      alt={`Preview of ${media.title}`}
                      className='media-picker-preview-image'
                    />
                    <div>
                      <p className='mb-1 text-black' style={{ margin: 0 }}>{media.title}</p>
                      <span>
                        {media.width > 0 && media.height > 0 && (
                          <>{media.width} x {media.height} - </>
                        )}
                        {media.size && formatSizeUnits(media.size)}
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default MediaDetails;
