import React from 'react';
import { Modal, ListGroup, Button } from 'react-bootstrap';
import { MediaItem } from '../interfaces';
import { getMediaUrl } from '../utils/media/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faX, faUpload } from '@fortawesome/pro-regular-svg-icons';

interface MediaViewerModalProps {
    show: boolean;
    handleClose: () => void;
    media: MediaItem[];
    currentMediaIndex: number;
    setCurrentMediaIndex: (index: number) => void;
    title?: string
}

const MediaViewerModal: React.FC<MediaViewerModalProps> = ({
    show,
    handleClose,
    media,
    currentMediaIndex,
    setCurrentMediaIndex,
    title
}) => {
    const currentMedia = media[currentMediaIndex];

    if (!currentMedia) {
        return null
    }

    return (
        <Modal show={show} onHide={handleClose} fullscreen>
            <Modal.Header className="d-flex justify-content-between align-items-center">
                <div className='d-flex'>
                    <Button
                        variant="secondary"
                        className="btn-soft-primary round-modal-close-button me-2"
                        style={{ padding: 0 }}
                        onClick={handleClose}
                    >
                        <FontAwesomeIcon icon={faX} size='sm' />
                    </Button>

                    <Modal.Title>{title}</Modal.Title>
                </div>
                <div>


                    <a href={`/${localStorage.getItem('companyId') || 'oc'}/media/${currentMedia.folderId}/${currentMedia.id}`} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="secondary"
                            className="btn-soft-primary me-2"
                        >
                            Zum Medium <FontAwesomeIcon icon={faUpload} size='sm' />
                        </Button>
                    </a>


                    <a href={getMediaUrl(currentMedia)} target="_blank" rel="noopener noreferrer">
                        <Button
                            variant="secondary"
                            className="btn-soft-primary me-2"
                        >
                            Herunterladen <FontAwesomeIcon icon={faDownload} size='sm' />
                        </Button>
                    </a>

                </div>
            </Modal.Header>
            <Modal.Body className="d-flex py-0">
                <div className="media-list me-3" style={{ width: '300px', overflowY: 'auto', maxHeight: '100%' }}>
                    <ListGroup>
                        {media.map((item, index) => (
                            <ListGroup.Item
                                className='py-3 no-border rounded'
                                key={item.id}
                                active={index === currentMediaIndex}
                                action
                                onClick={() => setCurrentMediaIndex(index)}
                            >
                                {item.title}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                <div className="media-preview flex-grow-1 d-flex justify-content-center align-items-center bg-grey" style={{ height: '100%' }}>
                    {currentMedia.mediaType === 'image' && (
                        <img src={getMediaUrl(currentMedia)} alt={currentMedia.title} className="img-fluid center-image" />
                    )}
                    {currentMedia.extension === 'pdf' && (
                        <iframe
                            src={getMediaUrl(currentMedia)}
                            title={currentMedia.title}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        ></iframe>
                    )}

                    {(currentMedia.mediaType !== 'image' && currentMedia.extension !== 'pdf') && (
                        <div className="text-center">
                            <h4>Leider steht für dieses Medium keine Vorschau zu Verfügung</h4>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MediaViewerModal;
